import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import memoji from '../../../assets/images/profile.png';
import './HeroSection.scss';

const HeroComponent = () => {
    // Implement your component logic here

    return (
        <div className='heroContainer-wrapper'>
            <div className='hero-sect-1'>
                <div className='hero-text-container'>
                    <p className='secondaryHerotext animated-text'>Hello, I'm Geetha Parthasarathy 🚀 !</p>
                    <Typography variant="h2" className='heroText'>A software engineer passionate about developing innovative solutions that enhance user experiences and drive business success. </Typography>
                    <p className='secondaryHerotext'>With a strong technical background and a focus on both frontend and backend technologies, I excel in creating high-performance applications and streamlining processes.</p>
                </div>
                <div>
                    <img src={memoji} height="300" width="300" alt="profile" />
                </div>
            </div>
            <div className='hero-scroll'>
                <div className="scroll-downs">
                    <div className="mousey">
                        <div className="scroller"></div>
                    </div>
                </div>
            </div>
        </div>


    );
};

const HeroSection: React.FC = HeroComponent;
export default HeroSection;