// src/routes/Routes.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainSection from '../common/MainSection/MainSection'; // Adjust the import paths as necessary
import WorkDetail from '../components/WorkDetailPage/WorkDetail';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainSection />} />
      <Route path="/work/:id" element={<WorkDetail />} />
    </Routes>
  );
};

export default AppRoutes;
