import React, { useState, useEffect } from 'react';
import { Grid, Drawer, List, ListItem, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useNavigate } from 'react-router-dom';
import './Header.scss';

const useWindowSize = () => {
    const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
    useEffect(() => {
        const handleResize = () => {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return size;
};

export const Header = () => {
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [width] = useWindowSize();
    const [navVisible, setNavVisible] = useState(false);

    const handleClick = () => {
        window.open('https://drive.google.com/file/d/1tKfLGn7XUu_WnvHTDi4IIrdbjDEaEoze/view?usp=sharing', '_blank');
    };

    const handleHome = () => {
        navigate(`/`);
    };

    const toggleDrawer = (open: boolean | ((prevState: boolean) => boolean)) => () => {
        setDrawerOpen(open);
    };

    const handleScrollToSection = (sectionId: string) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const workSection = document.getElementById('work-section');

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setNavVisible(true);
                } else {
                    setNavVisible(false);
                }
            },
            {
                root: null,
                threshold: 0.1,
            }
        );

        if (workSection) {
            observer.observe(workSection);
        }

        return () => {
            if (workSection) {
                observer.unobserve(workSection);
            }
        };
    }, []);

    return (
        <Grid className='navWrapper'>
            <Grid item>
                <svg width="60" height="60" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleHome} style={{ cursor: 'pointer' }}>
                    <svg width="60" height="60" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleHome} style={{ "cursor": "pointer" }}>
                        <path d="M7.00156 23.1651L16.946 13.4566L16.946 13.4566C22.7473 7.79303 25.6479 4.96125 29.1484 4.43009C30.2095 4.26908 31.2884 4.26422 32.3509 4.41567C35.8561 4.9153 38.7821 7.72084 44.634 13.3319L54.8696 23.1461C57.5707 25.7361 58.9212 27.031 59.7579 28.6388C60.0173 29.1374 60.2382 29.6551 60.4185 30.1875C61 31.9041 61 33.7752 61 37.5173V41.09C61 49.4446 61 53.6219 58.8481 56.5353C58.1968 57.4172 57.4172 58.1968 56.5353 58.8481C53.6219 61 49.4446 61 41.09 61H30.7297H20.91C12.5554 61 8.37806 61 5.46467 58.8481C4.58279 58.1968 3.80324 57.4172 3.15187 56.5353C1 53.6219 1 49.4446 1 41.09V37.4116C1 33.7147 1 31.8663 1.56866 30.1671C1.74505 29.64 1.96113 29.1271 2.21505 28.6327C3.03365 27.0388 4.35629 25.7476 7.00156 23.1651Z" stroke="#7B5F4C" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M30.9995 28.875C30.9995 25.5613 28.3132 22.875 24.9995 22.875H19.1527C19.1527 22.875 19.1527 22.875 19.1527 22.875C19.1527 22.875 17.3301 22.875 17.5123 25.5533V38.2753V41.5276C17.5123 42.5893 18.2116 42.9578 18.697 43.0857L23.8991 43.1436C24.3998 43.1492 24.8027 43.5566 24.8027 44.0573V44.0573C24.8027 44.562 24.3936 44.9711 23.8889 44.9711H19.808C18.5401 44.9711 17.5123 45.9989 17.5123 47.2668V47.2668C17.5123 48.5347 18.5401 49.5625 19.808 49.5625H27.9499C28.8175 49.5625 29.7461 49.4647 30.3338 48.8266V48.8266C30.8964 48.2157 30.9995 47.3151 30.9995 46.4847V28.875ZM24.8027 28.2956C24.8027 27.9433 24.5171 27.6577 24.1648 27.6577V27.6577C23.8125 27.6577 23.5269 27.9433 23.5269 28.2956V37.6374C23.5269 37.9897 23.8125 38.2753 24.1648 38.2753V38.2753C24.5171 38.2753 24.8027 37.9897 24.8027 37.6374V28.2956Z" fill="#916A4F" fill-opacity="0.71" />
                        <path d="M23.5269 28.2956C23.5269 27.9433 23.8125 27.6577 24.1648 27.6577V27.6577C24.5171 27.6577 24.8027 27.9433 24.8027 28.2956V37.6374C24.8027 37.9897 24.5171 38.2753 24.1648 38.2753V38.2753C23.8125 38.2753 23.5269 37.9897 23.5269 37.6374V28.2956Z" fill="#916A4F" fill-opacity="0.71" />
                        <path d="M17.5123 38.2753C17.5123 38.2753 17.5123 39.9014 17.5123 41.5276M17.5123 38.2753V25.5533C17.3301 22.875 19.1527 22.875 19.1527 22.875C19.1527 22.875 19.1527 22.875 19.1527 22.875H24.9995C28.3132 22.875 30.9995 25.5613 30.9995 28.875V46.4847C30.9995 47.3151 30.8964 48.2157 30.3338 48.8266V48.8266C29.7461 49.4647 28.8175 49.5625 27.9499 49.5625H19.808C18.5401 49.5625 17.5123 48.5347 17.5123 47.2668V47.2668C17.5123 45.9989 18.5401 44.9711 19.808 44.9711H23.8889C24.3936 44.9711 24.8027 44.562 24.8027 44.0573V44.0573C24.8027 43.5566 24.3998 43.1492 23.8991 43.1436L18.697 43.0857M17.5123 38.2753V41.5276M17.5123 41.5276C17.5123 42.5893 18.2116 42.9578 18.697 43.0857M19.1527 43.1537C19.1527 43.1537 18.955 43.1537 18.697 43.0857M24.1648 38.2753V38.2753C24.5171 38.2753 24.8027 37.9897 24.8027 37.6374V28.2956C24.8027 27.9433 24.5171 27.6577 24.1648 27.6577V27.6577C23.8125 27.6577 23.5269 27.9433 23.5269 28.2956V37.6374C23.5269 37.9897 23.8125 38.2753 24.1648 38.2753Z" stroke="white" />
                        <path d="M42.615 29.7377H31V51.2117C31 52.8277 32.31 54.1377 33.9259 54.1377C35.5419 54.1377 36.8518 52.8277 36.8518 51.2117V43.2932H42.615H43.2357C44.477 43.2932 44.477 39.0181 44.477 39.018V32.3445C44.743 30.259 42.615 29.7377 42.615 29.7377Z" fill="#916A4F" fill-opacity="0.71" />
                        <path d="M31 29.7377C31 29.7377 38.0791 29.7377 42.615 29.7377M31 29.7377V51.2117C31 52.8277 32.31 54.1377 33.9259 54.1377V54.1377C35.5419 54.1377 36.8518 52.8277 36.8518 51.2117V43.2932M31 29.7377H42.615M42.615 29.7377C42.615 29.7377 44.743 30.259 44.477 32.3445V39.018C44.477 39.018 44.477 39.018 44.477 39.018C44.477 39.0181 44.477 43.2932 43.2357 43.2932M43.2357 43.2932C41.9944 43.2932 36.8518 43.2932 36.8518 43.2932M43.2357 43.2932H42.615M36.8518 43.2932C36.8518 43.2932 41.5511 43.2932 42.615 43.2932M36.8518 43.2932H42.615M38.8101 38.2071V38.2071C39.2018 38.2071 39.5194 37.8895 39.5194 37.4978V35.8925C39.5194 35.5007 39.2018 35.1831 38.8101 35.1831V35.1831C38.4184 35.1831 38.1008 35.5007 38.1008 35.8925V37.4978C38.1008 37.8895 38.4184 38.2071 38.8101 38.2071Z" stroke="white" />
                    </svg>
                </svg>
            </Grid>
            {width > 768 ? (
                <>
                    <Grid item className='navBox mainNav'>
                        <Grid item className="mainHeader" spacing={2} display="flex" gap="10px">
                            <span className='navItem' onClick={() => handleScrollToSection('work-section')}>Work</span>
                            <span className='navItem' onClick={() => handleScrollToSection('project-section')}>Projects</span>
                            <span className='navItem' onClick={() => handleScrollToSection('contact-section')}>Contact</span>
                        </Grid>
                    </Grid>
                    <Grid item className='navBox resume'>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }} onClick={handleClick}>
                            <span className='navItem'>Resume</span>
                            <ArrowOutwardIcon style={{ color: '#916a4f' }} />
                        </div>
                    </Grid>
                </>
            ) : (
                <IconButton edge="start" color="inherit" aria-label="menu" className='burgerMenu' onClick={toggleDrawer(true)}>
                    <MenuIcon />
                </IconButton>
            )}
            <Drawer anchor='right' open={drawerOpen} onClose={toggleDrawer(false)}>
                <List>
                    <ListItem onClick={handleHome}>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem >
                        <ListItemText primary="Work" />
                    </ListItem>
                    <ListItem >
                        <ListItemText primary="Projects" />
                    </ListItem>
                    <ListItem >
                        <ListItemText primary="Contact" />
                    </ListItem>
                    <ListItem onClick={handleClick}>
                        <ListItemText primary="Resume" />
                    </ListItem>
                </List>
            </Drawer>
        </Grid>
    );
};

export default Header;
