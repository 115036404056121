import React, { useEffect, useRef } from 'react';
import ProjectCard from './ProjectCard/ProjectCard';
import project from '../../../assets/json/projectCard.json';
import { Grid } from '@mui/material';
import leadHawkImage from '../../../assets/images/myLeadOG.png'; // Import the leadHawkImage variable
import hailMaryImg from '../../../assets/images/TM-MAIN.png';
import './ProjectSection.scss';
import assignFlowrImg from '../../../assets/images/cloudProj.png';
import { ArrowOutwardOutlined } from '@mui/icons-material';

interface ProjectSectionProps {
    // Define the props for your component here
}

const imageMap: { [key: string]: string } = {
    leadHawk: leadHawkImage,
    hailMary: hailMaryImg,
    assignFlowr: assignFlowrImg,
};

const mapProjectImages = (projects: any[]) => {
    return projects.map(project => ({
        ...project,
        image: imageMap[project.id] // Replace the string with the actual image import
    }));
};

const ProjectSection: React.FC<ProjectSectionProps> = () => {
    const projectsWithImages = mapProjectImages(project['project-card']);
    const projectRefs = useRef<HTMLDivElement[]>([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fadeIn');
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (projectRefs.current) {
            projectRefs.current.forEach((ref) => {
                if (ref) observer.observe(ref);
            });
        }

        return () => {
            if (projectRefs.current) {
                projectRefs.current.forEach((ref) => {
                    if (ref) observer.unobserve(ref);
                });
            }
        };
    }, []);


    return (
        <>
        <Grid container justifyContent="center">
            {projectsWithImages.map((project, index) => (
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={4}
                    key={index}
                    maxWidth="30%"
                    className='projectCard-grid'
                    ref={(el) => (projectRefs.current[index] = el!)}
                >
                    <ProjectCard project={project} />
                </Grid>
            ))}

        </Grid>

        <Grid container justifyContent="flex-end" className='viewAllProjects-container'>
                <Grid item className='viewAllProjects'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }} onClick = {()=> window.open('https://github.com/geethaPsarathy?tab=repositories', '_blank')}>
                        <span className='viewProjectItem'>View all projects</span>
                        <ArrowOutwardOutlined style={{ color: '#916a4f' }}  />
                    </div>
                </Grid>
            </Grid>
        </>

    );
};

export default ProjectSection;
