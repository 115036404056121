import './App.scss';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { theme } from './theme/theme';
import Header from './common/Header/Header';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { useState, useEffect, useRef } from 'react';
import LoadingComponent from './common/LoadingComponent/LoadingComponent';


function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // 

    return () => clearTimeout(timer);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {loading ? (
        <LoadingComponent />
      ) : (
        <Router>
          <div className='app-Container'>
          <Header 
            />
            <AppRoutes />
            {/* <MainSection /> */}

          </div>
        </Router>
      )}
    </ThemeProvider>

  );
}

export default App;
