import { Button } from '@material-ui/core';
import { Typography } from '@mui/material';
import React from 'react';
import leadHawkImage from '../../assets/images/enhancedLH.png';
import hailMaryImg from '../../assets/images/enhancedLH.png';
import assignFlowrImg from '../../assets/images/cloud-arch.png';
import './WorkDetail.scss';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';

import projectOverview from '../../assets/json/projectOverview.json';
import Pill from '../../common/Pill/Pill';

interface WorkDetailProps {
    year: string;
    title: string;
    description: string;
    about: string;
    whatIdid: string[];
    role: string;
    techStack: string[];
    github: string;
    website: string;
    duration: string;
    image: string;
}
interface ProjectOverview {
    [key: string]: WorkDetailProps;
  }

  const imageMap: { [key: string]: string } = {
    leadHawk: leadHawkImage,
    hailMary: hailMaryImg,
    assignFlowr: assignFlowrImg,
  };
  

const WorkDetail: React.FC<WorkDetailProps> = () => {
    const id = window.location.pathname.split('/')[2];

  const projectOverviewData: { "project-overview": ProjectOverview } = projectOverview;
  const project = projectOverviewData["project-overview"][id];
  project.image = imageMap[id];
  

    const  handleLinkNavigation = (link : string) => { 
        if(link === 'website'){
            window.open(project.website, '_blank');
        }else{
            window.open(project.github, '_blank');
        }

    }

    return (
        <div className='projectContainer'>
            <div className='project-header'>
                <div className="project-period">
                    <DeveloperBoardIcon />
                    <span>WORK</span>
                    <p>{project.year}</p>
                </div>
                <Typography variant='h4' className='project-title'>{project.title} </Typography>
                <p className='description'>{project.description}</p>
            </div>
            <div className="imageContainer">
                <img src={project.image} alt="project" />
            </div>
            <div className="project-descriptionContainer">
                <div className="projectExplanationGrid">
                    <div className="aboutPart">
                        <div className="about">
                            <Typography className="subHeader" variant='h6'>About</Typography>
                            <p className='description' style={{paddingTop :"12px"}}>{project.about}</p>
                        </div>
                        <div className="myPart">
                            <Typography className="subHeader" variant='h6'>What I did</Typography>
                            {project.whatIdid.map((what) => ( <p key={what} className='description'  style={{paddingTop :"12px"}} dangerouslySetInnerHTML={{ __html: what }}></p> ))}
                          
                        </div>
                        <div className="myPart">
                            <Typography className="subHeader" variant='h6'>Done With</Typography>
                            <div className="techStack"  style={{paddingTop :"12px" ,paddingLeft: "20px"}}>
                            {project.techStack.map((tech) => ( <Pill key={tech} text={tech} /> ))}

                            </div>
                        </div>
                        </div>
                        <div className="roleLink">
                            <div className="role">
                                <Typography className="roleHeader" variant='body2'>MY ROLE</Typography>
                                <p className='description'>{project.role}</p>
                            </div>
                            <div className="role">
                                <Typography className="roleHeader" variant='body2'>DURATION</Typography>
                                <p className='description' >{project.duration}</p>
                            </div>
                            <div className="link">
                                {  project.github && <Button variant="outlined" className='buttonDesign'> GITHUB </Button>}
                                { project.website && <Button variant="outlined" className='buttonDesign' onClick={() => handleLinkNavigation('website')}> VISIT THE WEBSITE </Button>}
                            </div>
                        </div>

                    </div>
                </div>

            </div>
);
}

export default WorkDetail;