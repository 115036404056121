import { createTheme } from '@mui/material/styles';
import { GeistSans } from 'geist/font/sans';
import { GeistMono } from 'geist/font/mono';



export const theme = createTheme({
    breakpoints: {
        values: {
        
          md: 960,
          lg: 1280,
          xl: 1920,  xs: 0,
          sm: 600,
        },
      },
      palette: {
        mode: "light",
        background: {
          default: '#f7f7f7',
        },
        common: {
          black: '#232323',
          white: '#ffffff',
        },
        error: { 
          main: '#F2616D' 
        },
        grey: {
          100: '#efefef',
          200: '#dcdcdc',
          300: '#b7b7b7',
          400: '#787878',
          500: '#232323',
        },
        info: { 
          main: '#619DF2' 
        },
        primary: {
          main: '#C31924',
        },
        success: { 
          main: '#43BF58' 
        },
        warning: { 
          main: '#F2CE61' 
        },
      },
      shape: {
        borderRadius: 3,
      },
      typography: {
        fontFamily: "Neue Montreal, sans-serif",
        fontSize: 12,
        h1: {
          fontSize: '3rem',
          fontWeight: 700,
          color: "red",
        },
        h2: {
          fontSize: '2.5rem',
          fontWeight: 700,
          color: '#232323',
        },
        h3: {
          fontSize: '1.5rem',
          fontWeight: 700,
          color: '#232323',
        },
        body1: {
          fontSize: '1rem',
          fontWeight: 400,
          color: '#aaa',
        },
        body2: {
          fontSize: '12px',
          fontWeight: 400,
          color: '#232323',
        },
      },
})