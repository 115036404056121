import HeroSection from './HeroSection/HeroSection';
import './MainSection.scss';
import WorkExperience from './WorkExperience/WorkExperience';
import experienceData from '../../assets/json/workEx.json';
import { Typography } from '@mui/material';
import ProjectSection from './ProjectSection/ProjectSection';
import { BrowserRouter as Router } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import MailIcon from '@mui/icons-material/Mail';




const MainSection = () => {
    // Implement your component logic here

    const workSectionRef = useRef(null);
    const projectsSectionRef = useRef(null);
    const contactSectionRef = useRef(null);

    const scrollToSection = (ref: { current: { scrollIntoView: (arg0: { behavior: string; }) => void; }; }) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };


    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.sectionTitle');
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('in-view');
                        } else {
                            entry.target.classList.remove('in-view');
                        }
                    });
                },
                { threshold: 0.1 } // Adjust the threshold as needed
            );

            sections.forEach((section) => {
                observer.observe(section);
            });

            return () => {
                sections.forEach((section) => {
                    observer.unobserve(section);
                });
            };
        };

        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function handleContactIcon(contact: string) {
        if (contact === 'linkedin') {
            window.open('https://www.linkedin.com/in/geethaparthasarathy/', '_blank');
        } else if (contact === 'github') {
            window.open('https://github.com/geethaPsarathy', '_blank');
        }
        else {
            window.open('mailto:parthasarathy.g@northeastern.edu', '_blank');
        }
    }

    return (
        <div className='mainSectionContainer'>
            <HeroSection />

            {/* WorkExperience Section */}
            <div className="workExperienceContainer" id='work-section' ref={workSectionRef}>
                <Typography variant='h3' className='sectionTitle'>Work Experience</Typography>
                <div className="workExp-card">
                    {experienceData.experienceData.map((exp, index) => (
                        <WorkExperience key={index} experience={exp} />
                    ))}</div>
            </div>

            {/* Projects Sections */}

            <div className="workExperienceContainer projectsContainer"  id='project-section' ref={projectsSectionRef}>
                <Typography variant='h3' className='sectionTitle'>Projects</Typography>
                <div className="projects-card" >
                    <ProjectSection />
                </div>
            </div>

            {/* Contacts Section */}

            <div className=" workExperienceContainer projectsContainer" id="contact-section" ref={contactSectionRef}>
                <div className="contact-card" >
                    <div className='heroText-contact'>
                    Looking to collaborate, or have a chat? <br />
                    I’d love to connect virtually or over a hot chocolate :)
                    </div>

                    <div className='icons-sections'>
                        <LinkedInIcon style={{ cursor: 'pointer' }} onClick={() => handleContactIcon('linkedin')}/>
                        <GitHubIcon style={{ cursor: 'pointer' }} onClick={() => handleContactIcon('github')} />
                        <MailIcon style={{ cursor: 'pointer' }} onClick={() => handleContactIcon('mail')}/>

                    </div>
                </div>
            </div>

            {/* Footer Section */}
            <div className="footer-section">
                <div className="footer-text">
                    <p> © Designed and developed by Geetha Parthasarathy , 2024 🏎️</p>
                </div>
            </div>        


        </div>
    );
};

export default MainSection;