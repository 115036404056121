import React from 'react';
import './WorkExperience.scss';
import Pill from '../../Pill/Pill';

interface Role {
    title: string;
    duration: string;
  }
  
  interface Experience {
    company: string;
    period: string;
    roles: Role[];
    description: string[];
  }
interface WorkExperienceProps {
  experience: Experience;
}

const WorkExperience: React.FC<WorkExperienceProps> = ({ experience }) => {
    return (
        <div className="work-experience-card">
          <div className="company-info">
            <h2>{experience.company}</h2>
            {
              experience.period.includes('PRESENT') ? <Pill text="CURRENT" /> : null
            }
            <span className="period">{experience.period}</span>
          </div>
          
          <div className="roles-description">
            <div className="roles">
              {experience.roles.map((role, index) => (
                <h3 key={`${index}-${role.title}`}>{role.title}</h3>
              ))}
            </div>
            <div className="description">
             { experience.description.map((desc, index) => (
              <><p>{desc}</p><br /></>
              ))}
            </div>
          </div>
        </div>
      );
};

export default WorkExperience;
