import { Card, CardHeader, CardMedia, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React from 'react';
import './ProjectCard.scss';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useNavigate } from 'react-router-dom';
import bgImage from '../../../../assets/images/bg-card.png';
import Pill from '../../../Pill/Pill';


interface ProjectCardProps {
    project: {
        name: string;
        id: string;
        period: string;
        description: string[];
        image: string;
        domain: string;
    };
}

// Define the custom styles
const useStyles = makeStyles({
    "project-card-container": {
        backgroundColor: "rgb(248, 248, 248)",
        color: "rgba(0, 0, 0, 0.87)",
        borderRadius: "24px !important",
        // border: "1.5px groove rgba(0, 0, 0, .1)",
        boxShadow: "none !important",
        cursor: "pointer",
        margin: "1rem",
        padding: " 1.5rem",
        paddingBottom: "0",
        backgroundImage: `url(${bgImage})`, // Use the imported background image
        backgroundSize: 'cover', // Adjust the background size as needed
        backgroundRepeat: 'no-repeat', // Prevent repeating the background image
        backgroundPosition: 'center', // Center the background image
        // backgroundImage: "radial-gradient(circle at 50% 0, #333333c9, rgb(0 0 0 / 0%) 60%)"
        transition: "transform 0.5s ease",
        "&:hover": {
            transform: "scale(1.05)",
        },

    },

    // "image-container": {
    //     objectPosition: "center center",
    //     objectFit: "cover",
    //     // aspectRatio: 16 / 9,

    //     borderRadius: "24px",
    //     height: "320px",
    //     width: "100%",
    //     boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
    //     transition: "transform 0.5s ease",
    //     // "&:hover": {
    //     //     transform: "scale(1.05)",
    //     // }
    // },

    "image-container": {
        position: 'relative',
        overflow: 'hidden',
        aspectRatio: 16 / 9,
        // paddingTop: '56.25%', // 16:9 aspect ratio
        borderRadius: '24px 24px 0 0px',
        width: '100%',
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
    },
    cardMedia: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },


    projectName: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        justifyContent: 'space-between',
        fontFamily: "Neue Montreal, sans-serif",
        marginTop: "10px"

    },

    arrowOutwardIcon: {

        borderRadius: '50%',
        padding: '2px',
        margin: '2px',
        border: '1px solid #666',
        cursor: 'pointer',
        "& :hover": {
            backgroundColor: 'red',
            color: 'black',
        },

        "&.MuiSvgIcon-root": {
            width: '24px',
            height: '24px',
        }
    },

    root: {
        // fontFamily: 'Neue Montreal, sans-serif', // Replace with your desired font-family
        paddingBottom: 0,
        "& .MuiCardHeader-root": {
            paddingBottom: 0,

        },
        "& .MuiCardHeader-title": {
            fontFamily: "Neue Montreal, sans-serif", // Replace with your desired font-family
            fontSize: '1.5rem',
            fontWeight: 700,
            color: '#232323',
            margin: '0 0 10px 0',
        },
       
       

    },
    subheader: {
        fontFamily: 'Arial, sans-serif', // Replace with your desired font-family
    },

    descriptionPara: {
        paddingTop: 0,
        letterSpacing: ".4px",
        fontFamily: " Neue Montreal, sans-serif",
        fontSize: "12px",
        fontWeight: "300",
        lineHeight: "150%",
    }
});

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
    const classes = useStyles();

    const navigate = useNavigate();

    const handleIconClick = () => {
        navigate(`/work/${project.id}`);
    };

    return (
        <Card
            classes={{
                root: classes["project-card-container"],

            }}
            onClick={handleIconClick}
        >
            <CardHeader
                title={<>
                    <Pill text={project.domain} />
                    <div className={classes.projectName}>
                        {project.name}
                        <ArrowOutwardIcon className={classes.arrowOutwardIcon} fontSize="small" />
                    </div> </>}
              
                style={{ paddingBottom: 0 }}
            />
            <CardContent style ={{paddingTop: "5px"}}>
                <div className={classes.descriptionPara}>
                    {project.description.length > 0
                        ? project.description.join(' ')
                        : 'No description provided.'}
                </div>
            </CardContent>
            <div className={classes["image-container"]}>
                <CardMedia
                    component="img"
                    image={project.image}
                    alt={project.name}
                    className={classes.cardMedia}
                />
            </div>

        </Card>
    );
};

export default ProjectCard;